import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Button, Drawer, List, ListItem, ListItemText } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import { useThemeContext } from '../theme/ThemeContextProvider';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import XIcon from '@mui/icons-material/X';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import headerJson from './../json/header.json';
interface HeaderNavigationInterface{
  text: string;
  path: string;
}

interface HeaderContentInterface{
  fb: string;
  linkedin: string;
  twitter: string;
  email: string;
  phone: string;
}
interface HeaderInterface{
  navigation: HeaderNavigationInterface[];
  content: HeaderContentInterface;
}

const Header: React.FC = () => {
  const headerData = headerJson as HeaderInterface;
  const { toggleColorMode, mode } = useThemeContext();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const menuItems = [
    { text: 'Home', path: '/' },
    { text: 'About Us', path: '/about' },
    { text: 'Values & Principles', path: '/blog' },
    { text: 'Achievments', path: '/achievments' },
    { text: 'Contact Us', path: '/contact' }
  ];

  return (
    <>
      <AppBar position="static" sx={
        {
          backgroundColor: mode === "light" ? "#fff" : "#1d1d1d",
          color: mode === "light" ? "#1d1d1d" : "#fff",
          zIndex: "2"
        }
      }>
        <Toolbar>
          <Box sx={{ display: { xs: 'block', md: 'none' } }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            TRINITY GLOBAL ENTERPRISES INC.
          </Typography>
          <IconButton sx={{ fontSize:"unset", color: "#00896f", border: "1px dotted #00896f", marginRight: "10px", display: { xs: "none", md: 'block' } }}>
            <EmailIcon sx={{verticalAlign:"bottom", fontSize:"unset", lineHeight:1, width: "25px", height: "25px"}}/>
          </IconButton>
          <Typography variant="body1" component="div" sx={{ fontWeight: "bold", marginRight: "20px", lineHeight: 1, display: { xs: "none", md: 'block' } }}>
            EMAIL US <br /><span style={{ color: "#00896f", fontSize: "13px" }}>{headerData.content.email}</span>
          </Typography>
          <IconButton sx={{ fontSize:"unset", color: "#00896f", border: "1px dotted #00896f", marginRight: "10px", display: { xs: "none", md: 'block' } }}>
            <LocalPhoneIcon sx={{verticalAlign:"bottom", fontSize:"unset", lineHeight:1, width: "25px", height: "25px"}}/>
          </IconButton>
          <Typography variant="body1" component="div" sx={{ fontWeight: "bold", marginRight: "20px", lineHeight: 1, display: { xs: "none", md: 'block' } }}>
            <strong>CALL US</strong><br /><span style={{ color: "#00896f", fontSize: "13px" }}>{headerData.content.phone}</span>
          </Typography>
          <IconButton href={headerData.content.fb} sx={{ "&:hover": { color: "#23527c" }, display: { xs: "none", md: 'block' } }}>
            <FacebookIcon />
          </IconButton>
          <IconButton href={headerData.content.linkedin} sx={{ "&:hover": { color: "#337ab7" }, display: { xs: "none", md: 'block' } }}>
            <LinkedInIcon />
          </IconButton>
          <IconButton href={headerData.content.twitter} sx={{ "&:hover": { color: "#000" }, display: { xs: "none", md: 'block' } }}>
            <XIcon />
          </IconButton>
          &nbsp;&nbsp;
          <IconButton sx={{ ml: 1, display: "none" }} onClick={toggleColorMode} color="inherit">
            {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
          </IconButton>
          <div id="google_language_translator"></div>
        </Toolbar>
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={handleDrawerToggle}
          PaperProps={{style: {backgroundColor: "#f1f1f1", opacity: 0.9}}}
          ModalProps={{style: {backdropFilter: "blur(10px)"}}}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={handleDrawerToggle}
            onKeyDown={handleDrawerToggle}
          >
            <List>
              {headerData.navigation.map((item) => (
                <ListItem
                  key={item.text}
                  component={Link}
                  to={item.path}
                  sx={{
                    position:"relative",
                    color: "#000",
                    '&:hover': {
                      backgroundColor: "#00896f",
                      color: "#fff",
                      cursor:"pointer"
                    },
                    '&::after': {
                      content: '""',
                      height: "1px",
                      position: "absolute",
                      background: "#00896f",
                      left: "10px",
                      right: "10px",
                      bottom: "0px",
                      opacity: 0.1
                    }
                  }}
                >
                  <ListItemText primary={item.text} />
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      </AppBar>
      <div style={
        {
          margin: "auto",
          backgroundColor: "#000",
          color: "#fff"
        }
      }>
        <Box sx={{ display: { xs: 'none', md: 'block' }, padding: "10px 0px" }} textAlign={"center"} >
          {menuItems.map((item) => (
            <Button
              key={item.text}
              color="inherit"
              component={Link}
              to={item.path}
              sx={{
                '&:hover' : {
                   color: "#00896f",
                   cursor: "pointer",
                }
              }}
            >
              {item.text}
            </Button>
          ))}
        </Box>
      </div>
    </>
  );
};

export default Header;
