export enum ComponentType {
    GRID_STLYE_TARA = "GRID_STLYE_TARA", 
    GRID_STLYE_SARA = "GRID_STLYE_SARA",
    GRID_STLYE_MARA = "GRID_STLYE_MARA",
    CAROUSEL_STYLE_SILK = "CAROUSEL_STYLE_SILK",
    BANNER_STYLE_ROCK= "BANNER_STYLE_ROCK" 
}

export interface ComponentData {
    type: ComponentType;
    title?: string;
    backgroundcolor?: string;
    backgroundimage?: string;
    data: any; // Additional props can vary based on component type
}

export interface PageInfo {
    title: string;
    description: string;
    components: ComponentData[];
}