import { Grid, Paper, Typography, styled } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Link } from "react-router-dom";

interface GridInterfaceSara {
    img: string;
    head: string;
    desc: string;
    url: string;
}

export default function GridStyleSara(data: any) {

    var ndata = data.data as GridInterfaceSara[];

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: '#fff',
        textAlign: 'left',
        boxShadow: "none",
        padding: "15px"
    }));

    const GridItem = (item: GridInterfaceSara) => {
        return (
            <Grid item xs={12} sm={6} lg={4} key={item.head}>
                <Item style={{ textAlign: "left" }}>
                    <img src={item.img} alt={item.head} style={{ marginBottom: "15px", width: "100%" }} />
                    <Typography variant="h5" component="div" sx={{
                        flexGrow: 1,
                        marginBottom: "20px", textAlign: "left"
                    }}>
                        {item.head}
                    </Typography>
                    <Typography variant="body1" component="div" sx={{
                        flexGrow: 1, overflow: 'hidden',
                        textAlign: "justify",
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        WebkitLineClamp: '3',
                        WebkitBoxOrient: 'vertical',
                    }}>
                        {item.desc}
                    </Typography>
                    <Link to={item.url} style={{ textDecoration: "none" }}>
                        <Typography variant="body1" component="div" sx={{
                            flexGrow: 1,
                            textAlign: "left",
                            marginTop: "10px",
                            fontStyle: "italic",
                            position: 'relative',
                            color: "#000000",
                            cursor: "pointer",
                            display: "inline-block",
                            fontSize: "14px",
                            '&::after': {
                                content: '""',
                                height: "2px",
                                position: "absolute",
                                background: "#00896f",
                                left: "-9px",
                                right: "-25px",
                                bottom: "-10px",
                            },
                            '&:hover': {
                                color: "#00896f",
                            }
                        }}>
                            Read More <ArrowForwardIosIcon style={{ fontSize: 12 }} />
                        </Typography>
                    </Link>
                </Item>
            </Grid>
        );
    }

    return (
        <div style={{ backgroundColor: "#ffffff", paddingTop: "30px", paddingBottom: "30px" }}>
            <Typography variant="h5" component="div" sx={{
                flexGrow: 1,
                marginBottom: "30px",
                textAlign: "center",
                fontWeight: "bold",
                position: "relative",
                '&::after': {
                    content: '""',
                    height: "2px",
                    position: "absolute",
                    background: "#00896f",
                    left: "50%",
                    marginLeft: "-35px",
                    bottom: "-15px",
                    width: "70px"
                }
            }}>
                {data.title}
            </Typography>
            <Grid container maxWidth={"1300px"} margin={"auto"}>
                {
                    ndata.map(dataitem => GridItem(dataitem))
                }
            </Grid>
        </div>
    );
};
