import { Typography } from '@mui/material'

interface BannerInterfaceRock{
    name: string;
    img: string;
}

export default function BannerStyleRock(data: any)
{
    var nData = data.data as BannerInterfaceRock;
    return (
        <div key={nData.name} style={{position: "relative"}}>
                <img src={nData.img} alt={nData.name} style={{verticalAlign:"bottom", width: "100%"}}/>
                <div style={{position:"absolute", left: 0, top:0, width: "100%", height: "100%", display:"table"}}>
                    <div style={{display:"table-cell", verticalAlign: "middle", textAlign:"center"}}>
                        <div style={{display:"inline-block", padding: "10px 15px", backgroundColor: "rgba(0, 137, 111, 1)"}}>
                            <Typography variant='h5' style={{color: "#fff", fontWeight: "bold"}} ><span dangerouslySetInnerHTML={{__html: nData.name}} /></Typography>
                        </div>
                    </div> 
                </div>
            </div>
    )
}