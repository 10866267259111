import Carousel from 'react-material-ui-carousel'
import { Button, Typography } from '@mui/material'
import { Link } from '@mui/icons-material';

interface CarouselInterfaceSilk{
    name: string;
    desc: string;
    img: string;
    url: string;
    buttontxt: string;
}

export default function CarouselStyleSilk(data: any)
{
    var ndata = data.data as CarouselInterfaceSilk[];

    var getCaroselItem = (carouselItem: CarouselInterfaceSilk) => {
        return (
            <div key={carouselItem.name} style={{position: "relative"}}>
                <img src={carouselItem.img} alt={carouselItem.name} style={{verticalAlign:"bottom", width: "100%"}}/>
                <div style={{position:"absolute", left: 0, top:0, width: "100%", height: "100%", display:"table"}}>
                    <div style={{display:"table-cell", verticalAlign: "middle", textAlign:"center"}}>
                        <div style={{display:"inline-block", padding: "15px 30px", backgroundColor: "rgba(0, 137, 111, 0.9)"}}>
                            <Typography variant='h5' color={"#fff"} ><span dangerouslySetInnerHTML={{__html: carouselItem.name}} /></Typography>
                            <Typography variant='body1' color={"#fff"}><span dangerouslySetInnerHTML={{__html: carouselItem.desc}} /></Typography>
                            {
                                carouselItem.buttontxt && (
                                    <Link to={carouselItem.url}>
                                        <Button >
                                            {carouselItem.buttontxt}
                                        </Button>
                                    </Link>
                                )
                            }
                        </div>
                    </div> 
                </div>
            </div>
        );
    }

    return (
        <Carousel 
            navButtonsProps={{
                style: {
                    borderRadius: 0,
                    backgroundColor: "#00896f",
                }
            }}    
            indicators={false}>
            {
                ndata.map( (item, i) => getCaroselItem(item) )
            }
        </Carousel>
    )
}