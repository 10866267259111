import { Divider, Grid, IconButton, InputBase, ListItemIcon, MenuItem, MenuList, Paper, Typography } from "@mui/material";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import PlaceIcon from '@mui/icons-material/Place';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import footerJson from './../json/footer.json';

interface FooterNavigationItemInterface{
    text: string;
    url: string;
}

interface FooterNavigationInterface{
    head: string;
    items: FooterNavigationItemInterface[];
}

interface FooterNewsLetterInterface{
    head: string;
    desc: string;
}

interface FooterStayConnectedLocationInterface{
    head: string;
    location: string;
}

interface FooterStayConnectedItemInterface{
    location: FooterStayConnectedLocationInterface;
    phone: string;
    email: string;
}

interface FooterStayConnectedInterface{
    head: string;
    items: FooterStayConnectedItemInterface[];
}

interface FooterInterface{
    navigation: FooterNavigationInterface;
    newsletter: FooterNewsLetterInterface;
    stayconnected: FooterStayConnectedInterface;
    copyright: string;
}

export const Footer = () => {
    var data = footerJson as FooterInterface;

    var getNavItem = (navItem: FooterNavigationItemInterface) => {
        return (
            <MenuItem key={navItem.text} style={{padding:"2px 0px"}}>
                <ListItemIcon style={{minWidth: "20px"}}>
                    <ArrowForwardIosIcon style={{fontSize:"13px"}} />
                </ListItemIcon>
                <span>{navItem.text}</span>
            </MenuItem>
        );
    }

    var getConnectedItem = (connectItem: FooterStayConnectedItemInterface) => {
        return (
            <>
                {connectItem.location.head && (
                    <>
                        <MenuItem key={connectItem.location.head} sx={{'&:hover': { backgroundColor: "transparent", cursor:"unset"}}} style={{padding:"0px"}}>
                            <ListItemIcon style={{minWidth: "20px"}}>
                                <PlaceIcon style={{fontSize:"13px"}} />
                            </ListItemIcon>
                            <strong>{connectItem.location.head}:</strong>
                        </MenuItem>
                        <MenuItem key={connectItem.location.location} sx={{'&:hover': { backgroundColor: "transparent", cursor:"unset"}}} style={{padding:"0px"}}>
                            <ListItemIcon style={{minWidth: "20px"}}>
                            </ListItemIcon>
                            <span style={{textWrap:"wrap"}}>{connectItem.location.location}</span>
                        </MenuItem>
                    </>
                )}
                {connectItem.phone && (
                    <>
                        <MenuItem key={connectItem.phone} sx={{'&:hover': { backgroundColor: "transparent", cursor:"unset"}}} style={{padding:"5px 0px"}}>
                            <ListItemIcon style={{minWidth: "20px"}}>
                                <LocalPhoneIcon style={{fontSize:"13px"}} />
                            </ListItemIcon>
                            <strong>Phone:</strong>&nbsp;{connectItem.phone}
                        </MenuItem>
                    </>
                )}
                {connectItem.email && (
                    <>
                        <MenuItem key={connectItem.phone} sx={{'&:hover': { backgroundColor: "transparent", cursor:"unset"}}} style={{padding:"5px 0px"}}>
                            <ListItemIcon style={{minWidth: "20px"}}>
                                <EmailIcon style={{fontSize:"13px"}} />
                            </ListItemIcon>
                            <strong>E-mail:</strong>&nbsp;{connectItem.email}
                        </MenuItem>
                    </>
                )}
            </>
        );
    }
    
    var footerSecHead = (txt: String) => {
        return <Typography variant="h6" style={{color: "#00896f"}}>{txt}</Typography>
    }

    return (
        <div style={{borderTop:"1px solid #ccc", backgroundColor: "#f1f1f1"}}>
            <div style={{padding: "30px 0px"}}>
                <Grid container style={{maxWidth: "1300px", margin:"auto"}}>
                    <Grid key={data.newsletter.head} item xs={12} sm={12} lg={4} style={{padding: "15px", paddingRight:"70px"}}>
                        {footerSecHead(data.newsletter.head)}
                        <Typography variant="body1" style={{padding:"15px 0px"}}>{data.newsletter.desc}</Typography>
                        <Paper
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', margin:"5px 0px", boxShadow: "none", border: "1px solid #00896f" }}
                            >
                            <InputBase
                                sx={{ ml: 1, flex: 1, backgroundColor: "transparent"}}
                                placeholder="Your Email..."
                                inputProps={{ 'aria-label': 'Enter your email' }}
                            />
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton sx={{ p: '10px', color: "#00896f", borderRadius: 0 }} aria-label="directions">
                                <EmailIcon />
                            </IconButton>
                        </Paper>
                    </Grid>
                    <Grid key={data.navigation.head} item xs={12} sm={12} lg={4} style={{padding: "15px"}}>
                        {footerSecHead(data.navigation.head)}
                        <MenuList>
                            {data.navigation.items.map(navitem => getNavItem(navitem))}
                        </MenuList>
                    </Grid>
                    <Grid key={data.stayconnected.head} item xs={12} sm={12} lg={4} style={{padding: "15px"}}>
                        {footerSecHead(data.stayconnected.head)}
                        <MenuList>
                            {data.stayconnected.items.map(connectItem => getConnectedItem(connectItem))}
                        </MenuList>
                    </Grid>
                </Grid>
            </div>
            <div style={{backgroundColor: "#4d4d4d", textAlign: "center", color: "#fff", padding: "10px"}}>{data.copyright}</div>
        </div>
    );
}